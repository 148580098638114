import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { AccountContext } from './Account';
import { Link, useNavigate } from 'react-router-dom';
import { Amplify, API, I18n } from 'aws-amplify';
import config from '../aws-exports';
import { Authenticator, translations } from '@aws-amplify/ui-react';
import { CSVLink, CSVDownload } from 'react-csv';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(config)

I18n.putVocabularies(translations);
I18n.setLanguage('es');
I18n.putVocabularies({
  es: {
    'Create Account': 'Crear Cuenta',
  }
})

const formFields = {
  signIn: {
    username: {
      dialCode: '+1',
      label: 'Número Telefónico',
      placeholder: '1234567890',
      isRequired: true
    },
    password: {
      label: 'Contraseña',
      placeholder: 'Ingrese su contraseña'
    },
  },
  signUp: {
    phone_number: {
      dialCode: '+1',
      label: 'Número Telefónico',
      placeholder: '1234567890',
      isRequired: true,
      order: 3,
    },
    email: {
      label: 'Correo Electronico',
      placeholder: 'ejemplo@ejemplo.com',
      isRequired: true,
      order: 4,
    },
    family_name: {
      label: 'Apellido',
      placeholder: 'Ingrese su apellido',
      isRequired: true,
      order: 2,
    },
    given_name: {
      label: 'Primer Nombre',
      placeholder: 'Ingrese su primer nombre',
      isRequired: true,
      order: 1,
    },
    password: {
      label: 'Contraseña',
      placeholder: 'Ingrese su contraseña',
      isRequired: true,
      order: 5,
    },
    confirm_password: {
      label: 'Confirmar Contraseña',
      placeholder: 'Confirme su contraseña',
      isRequired: true,
      order: 6,
    },
  },
}

const DisplayChildData = () => {
  const [childrenList, setChildrenList] = useState([]);
  const [parentList, setParentList] = useState([]);
  // State to store checkbox status for each service
  const [firstServiceCheckedRows, setFirstServiceCheckedRows] = useState({});
  const [secondServiceCheckedRows, setSecondServiceCheckedRows] = useState({});
  const [thirdServiceCheckedRows, setThirdServiceCheckedRows] = useState({});
  const [childrenObjs, setChildrenObjs] = useState({});
  const [parentObjs, setParentObjs] = useState({});
  // State to store search criteria and results
  const [searchCriteria, setSearchCriteria] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  // State to manage expanded rows
  const [expandedRows, setExpandedRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const csvLink = useRef();

  const refreshList = () => {
    API.get('childcareUsers', '/childcareusers/children').then((childrenRes) => {
      childrenRes.map((child, index) => {
        // console.log(child.UUID)
        setChildrenObjs((prevState) => ({
          ...prevState,
          [child.UUID]: [child],
        }));
      })
      // setChildrenList(childrenRes)
    });

    API.get('childcareUsers', '/childcareusers/parents').then((parentRes) => {
      parentRes.map((parent, index) => {
        setParentObjs((prevState) => ({
          ...prevState,
          [parent.UUID]: [parent],
        }));
      })
      // console.log(parentRes)
      // setParentList(parentRes)
    });

    // console.log(uuids["8f3276f7-71d0-52f5-adea-51a6b082906b"])

    // console.log("CHECKBOX" + firstServiceCheckedRows["525d7894-8ce4-5e28-9013-f06627242e46"]);
    // Object.entries(childrenObjs).forEach(([key, value], index) => {
    //   // const uuid = childrenObjs[rowId][0];
    //   console.log(index);
    //   console.log(key);
    //   console.log(value[0]);
    //   // console.log(uuids["8f3276f7-71d0-52f5-adea-51a6b082906b"])
    //   // rowId is the unique identifier for the row
    //   // isChecked is a boolean indicating whether the row is checked (true) or unchecked (false)
    //   // You can perform operations or checks with rowId and isChecked here
    // });
    // console.log(thirdServiceCheckedRows["indexes"]);
  }

  const downloadCSV = () => {
    const csvDataArray = [
      ['Last Name', 'First Name', 'DOB', 'Age', 'Allergies/Special Needs', 'Church Name', '11/25AM Check-in', '11/25PM Check-in', '11/26AM Check-in', '1 Parent Last Name', '1 Parent First Name', '1 Parent Phone Num', '2 Parent Last Name', '2 Parent First Name', '2 Parent Phone Num', 'Parent Comment', 'Registration Date']
    ];
    Object.entries(childrenObjs).forEach(([key, value], index) => {
      // const uuid = childrenObjs[rowId][0];
      // console.log(index);
      // console.log(key);
      console.log(value[0].LastName);
      const childCSVData = [value[0].LastName, value[0].FirstName, value[0].DOB, calculateAge(value[0].DOB).toString(), value[0].Allergies, parentObjs[value[0].parentsUUIDs[0]][0].ChurchName, firstServiceCheckedRows[key] === true ? "true" : "false", secondServiceCheckedRows[key] === true ? "true" : "false", thirdServiceCheckedRows[key] === true ? "true" : "false"];
      // const childCSVData = [value[0].LastName, value[0].FirstName, value[0].DOB, calculateAge(value[0].DOB).toString(), value[0].Allergies, parentObjs[value[0].parentsUUIDs[0]][0].ChurchName, firstServiceCheckedRows[key].toString(), secondServiceCheckedRows[key].toString(), thirdServiceCheckedRows[key]];

      value[0].parentsUUIDs.map((parent, index) => {
        if (parentObjs[parent]){
          childCSVData.push(parentObjs[parent][0].LastName);
          childCSVData.push(parentObjs[parent][0].FirstName);
          childCSVData.push("("+parentObjs[parent][0].PhoneNumber.substring(2).slice(0,3)+") "+parentObjs[parent][0].PhoneNumber.substring(2).slice(3,6)+"-"+parentObjs[parent][0].PhoneNumber.substring(2).slice(6,15));
        }
      });
      if (value[0].parentsUUIDs.length === 1){
        childCSVData.push("", "", "");
      };
      childCSVData.push(parentObjs[value[0].parentsUUIDs[0]][0].Comments);
      childCSVData.push(new Date(parentObjs[value[0].parentsUUIDs[0]][0].registrationDatetime).toLocaleString());

      csvDataArray.push(childCSVData);
    });

    setCsvData(csvDataArray);
    setTimeout(() => {
      csvLink.current.link.click();
    });
    // console.log(csvData);
    // return csvData;
  };

  useLayoutEffect(() => {
    API.get('childcareUsers', '/childcareusers/children').then((childrenRes) => {
      childrenRes.map((child, index) => {
        setCheckboxes(child.UUID, [child.firstCheckin, child.secondCheckin, child.thirdCheckin])
        setFirstServiceCheckedRows((prevState) => ({
          ...prevState,
          [child.UUID]: child.firstCheckin,
        }));
        setChildrenObjs((prevState) => ({
          ...prevState,
          [child.UUID]: [child],
        }));
      })
      // console.log(childrenRes)
      // setChildrenList(childrenRes)
    });

    API.get('childcareUsers', '/childcareusers/parents').then((parentRes) => {
      parentRes.map((parent, index) => {
        setParentObjs((prevState) => ({
          ...prevState,
          [parent.UUID]: [parent],
        }));
      })
      // console.log(parentRes)
      // setParentList(parentRes)
    });
  }, []);

  // useEffect(() => {
  //   setCsvData(downloadCSV());
  // }, []);

  const setCheckboxes = (uuid, checkBoxStatus) => {
    setFirstServiceCheckedRows((prevState) => ({
      ...prevState,
      [uuid]: checkBoxStatus[0],
    }));
    setSecondServiceCheckedRows((prevState) => ({
      ...prevState,
      [uuid]: checkBoxStatus[1],
    }));
    setThirdServiceCheckedRows((prevState) => ({
      ...prevState,
      [uuid]: checkBoxStatus[2],
    }));
  };

  // console.log(parentList.filter((item) => item["UUID"] === childrenList[0].parentsUUIDs[0]))
  // console.log(parentList[0].ChurchName)

  // Dummy data for children and parents
  let dummyData = [
    {
      id: 1,
      child: {
        firstName: 'John',
        lastName: 'Doe',
        dateOfBirth: '01/15/2010',
        allergies: 'Peanuts',
      },
      parents: [
        {
          firstName: 'Jane',
          lastName: 'Doe',
          phoneNumber: '555-123-4567',
          churchName: 'First Church',
          comment: 'Lorem ipsum dolor sit amet',
        },
        {
          firstName: 'Bob',
          lastName: 'Smith',
          phoneNumber: '555-987-6543',
        },
      ],
      firstService: false, // Add a property to track checkbox status
      secondService: false, // Add a property to track checkbox status
      thirdService: false, // Add a property to track checkbox status
    },
    {
      id: 2,
      child: {
        firstName: 'Emma',
        lastName: 'Johnson',
        dateOfBirth: '05/20/2012',
        allergies: 'None',
      },
      parents: [
        {
          firstName: 'Michael',
          lastName: 'Johnson',
          phoneNumber: '555-555-5555',
          churchName: 'Second Church',
          comment: 'Lorem ipsum dolor sit amet',
        },
        {
          firstName: 'Sarah',
          lastName: 'Johnson',
          phoneNumber: '555-666-6666',
        },
      ],
      firstService: false, // Add a property to track checkbox status
      secondService: false, // Add a property to track checkbox status
      thirdService: false, // Add a property to track checkbox status
    },
    // Add more dummy data here...
  ];

  // Function to filter a dictionary based on search criteria
  const handleSearch = (event) => {
    event.preventDefault();
    
    return Object.keys(childrenObjs).reduce((result, key) => {
      const item = childrenObjs[key][0];
      const childFirstNameNormalized = item.FirstName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const childLastNameNormalized = item.LastName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      // Customize the search logic based on your requirements
      const searchString = `${childFirstNameNormalized} ${childLastNameNormalized} ${item.DOB} ${item.Allergies} ${item.parentsUUIDs
        .map((parentUUID) => {
          if (parentObjs[parentUUID]){
            const parent = parentObjs[parentUUID][0];
            const parentFirstNameNormalized = parent.FirstName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            const parentLastNameNormalized = parent.LastName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            return `${parentFirstNameNormalized} ${parentLastNameNormalized} ${parent.PhoneNumber} ${parent.ChurchName} ${parent.Comments}`;
          }
        })
        .join(' ')}`.toLowerCase();

      if (searchString.includes(searchCriteria.toLowerCase())) {
        // console.log(key)
        result[key] = [item];
      }
        // console.log("result" + result);
        setSearchResults(result);
        return result;
    }, {});
  };

  // // Example usage
  // const searchCriteria = "John"; // Replace with your search criteria
  // const filteredData = filterDictionary(dummyData, searchCriteria);
  // console.log(filteredData);


  // // Function to handle search
  // const handleSearch = () => {
  //   // Filter the dummyData based on the search criteria
  //   const filteredResults = dummyData.filter((entry) => {
  //     // You can customize this search logic based on your requirements
  //     const { child, parents } = entry;
  //     const searchString = `${child.firstName} ${child.lastName} ${child.dateOfBirth} ${child.allergies} ${parents
  //       .map((parent) => `${parent.firstName} ${parent.lastName} ${parent.phoneNumber} ${parent.churchName} ${parent.comment}`)
  //       .join(' ')}`.toLowerCase();

  //     return searchString.includes(searchCriteria.toLowerCase());
  //   });

  //   // Update the search results
  //   setSearchResults(filteredResults);
  // };

  // Display all data when no search criteria is entered
  const displayedData = searchCriteria === '' ? childrenObjs : searchResults;

  // Function to toggle row expansion
  const toggleRowExpansion = (rowId) => {
    setExpandedRows((prevState) =>
      prevState.includes(rowId) ? prevState.filter((id) => id !== rowId) : [...prevState, rowId]
    );
  };

    // Function to handle checkbox change
    const handleFirstServiceCheckboxChange = (rowId) => {
      // Update the isChecked property for the corresponding row
      const updatedData = dummyData.map((entry) =>
        entry.id === rowId ? { ...entry, isChecked: !entry.isChecked } : entry
      );
  
      // Update the dummy data
      dummyData = updatedData;
    };

      // Function to handle checkbox change
  const handleSecondServiceCheckboxChange = (rowId) => {
    // Update the isChecked property for the corresponding row
    const updatedData = dummyData.map((entry) =>
      entry.id === rowId ? { ...entry, secondService: !entry.secondService } : entry
    );
  
    // Update the dummy data
    dummyData = updatedData;
  };

      // Function to handle checkbox change
  const handleThirdServiceCheckboxChange = (rowId) => {
    // Update the isChecked property for the corresponding row
    const updatedData = dummyData.map((entry) =>
      entry.id === rowId ? { ...entry, thirdService: !entry.thirdService } : entry
    );
  
    // Update the dummy data
    dummyData = updatedData;
  };

  // Function to handle checkbox toggle for each service
  const handleFirstServiceCheckboxToggle = (index, uuid, dateOfRegistration, firstName, lastName, dob, allergies, parentUUIDs, isChecked) => {
    API.post('childcareUsers', '/childcareusers/children', {
      body: {
        UUID: uuid,
        registrationDatetime: dateOfRegistration,
        FirstName: firstName,
        LastName: lastName,
        DOB: dob,
        Allergies: allergies,
        firstCheckin: isChecked,
        secondCheckin: secondServiceCheckedRows[uuid],
        thirdCheckin: thirdServiceCheckedRows[uuid],
        parentsUUIDs: parentUUIDs,
        registeredCurrentYear: 2023,
      },
    })
    
    setFirstServiceCheckedRows((prevState) => ({
      ...prevState,
      [uuid]: isChecked,
    }));
  };

  const handleSecondServiceCheckboxToggle = (index, uuid, dateOfRegistration, firstName, lastName, dob, allergies, parentUUIDs, isChecked) => {
    API.post('childcareUsers', '/childcareusers/children', {
      body: {
        UUID: uuid,
        registrationDatetime: dateOfRegistration,
        FirstName: firstName,
        LastName: lastName,
        DOB: dob,
        Allergies: allergies,
        firstCheckin: firstServiceCheckedRows[uuid],
        secondCheckin: isChecked,
        thirdCheckin: thirdServiceCheckedRows[uuid],
        // firstParentsUUID: firstParentUUID,
        // secondParentsUUID: secondParentUUID,
        parentsUUIDs: parentUUIDs,
        registeredCurrentYear: 2023,
      },
    })
    
    setSecondServiceCheckedRows((prevState) => ({
      ...prevState,
      [uuid]: isChecked,
    }));
  };

  const handleThirdServiceCheckboxToggle = (index, uuid, dateOfRegistration, firstName, lastName, dob, allergies, parentUUIDs, isChecked) => {
    // if (isChecked) {
      API.post('childcareUsers', '/childcareusers/children', {
        body: {
          UUID: uuid,
          registrationDatetime: dateOfRegistration,
          FirstName: firstName,
          LastName: lastName,
          DOB: dob,
          Allergies: allergies,
          firstCheckin: firstServiceCheckedRows[uuid],
          secondCheckin: secondServiceCheckedRows[uuid],
          thirdCheckin: isChecked,
          // firstParentsUUID: firstParentUUID,
          // secondParentsUUID: secondParentUUID,
          parentsUUIDs: parentUUIDs,
          registeredCurrentYear: 2023,
        },
      })
    // }
    // else {
    //   API.post('childcareUsers', '/childcareusers/children', {
    //     body: {
    //       UUID: "840df982-3856-5683-93ca-fed876b475df",
    //       registrationDatetime: "2023-10-26T03:06:40.055Z",
    //       FirstName: "Hura",
    //       LastName: "Hucra",
    //       DOB: "2023-10-26",
    //       Allergies: "",
    //       firstCheckin: false,
    //       secondCheckin: false,
    //       thirdCheckin: false,
    //       parentsUUIDs: ["c638437d-0384-5bd9-a779-fa93498bdd07"],
    //       registeredCurrentYear: 2023,
    //     },
    //   })
    // };

    // // refreshList();
    setThirdServiceCheckedRows((prevState) => ({
      ...prevState,
      [uuid]: isChecked,
    }));
  };
  
  // useEffect(() => {
  //     API.get('childcareUsers', '/childcareusers/children').then(childrenRes => console.log(childrenRes));
  //   },[])

  const calculateAge = (dob) => {
    // console.log(new Date());
    // console.log(new Date(dob));
    const ageDifMs = new Date() - new Date(dob);
    // console.log(ageDifMs);
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    // console.log(ageDate);
    const age = Math.abs(ageDate.getFullYear() - 1970); 
    return age;
  };

  const childrenCount = () => {
    return Object.keys(childrenObjs).length
  }

  const isEmpty = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false; // The object has at least one key
      }
    }
    return true; // The object is empty (has no keys)
  };

  return (
    <div>
      {!isEmpty(parentObjs) ? (
      <Authenticator formFields={formFields}>
        {({ signOut, user }) => (
        <div className="container mt-4">
          <h2>Busqueda</h2>
          <Form onSubmit={handleSearch}>
            <div className="d-flex justify-content-between align-items-center">
              <Form.Group className="flex-grow-1 mb-2 mx-2">
                <Form.Control
                  type="text"
                  placeholder="Buscar por nombre, fecha de nacimiento, iglesia, número telefónico, etc."
                  value={searchCriteria}
                  onChange={(e) => {
                      setSearchCriteria(e.target.value);
                      handleSearch(e);
                    }
                  }
                />
              </Form.Group>
              {/* <Button variant="primary" type="submit" className='mb-2 mx-2'>
                Buscar
              </Button> */}
            </div>
          {/* <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name, date of birth, allergies, etc."
              value={searchCriteria}
              // onChange={(e) => setSearchCriteria(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn btn-primary" type="button" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div> */}
          </Form>
          <Button variant="success" style={{float: "left"}} className="mb-2" onClick={() => refreshList()}>Actualizar Lista</Button>
          <Button onClick={downloadCSV}>Bajar Archivo CSV</Button>
          <CSVLink 
            data={csvData}
            filename={'childCareList.csv'}
            className='hidden'
            ref={csvLink}
            target='_blank'
          />
          <Button variant="danger" style={{float: "right"}} className="d-flex mb-2"onClick={signOut}>Cerrar Sesión</Button>
          <p className="date-label ">*COLOR <span className="text-danger">ROJO</span> = Alergia o necesidad especial. || Niños total: {childrenCount()}</p>
          {/* <p className="date-label ">Niños total: {childrenCount()}</p> */}
          <div>
            {displayedData.length === 0 ? (
              <p>No results found.</p>
            ) : (
              <div className="table-responsive-md">
                <table className="table table-striped table-sm table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Nombre del Niño</th>
                      <th>Fecha de Nacimiento</th>
                      {/* <th>Número Telefónico</th> */}
                      <th>Iglesia</th>
                      <th>Alergia/Necesidad Especial</th>
                      <th>11/25 AM</th>
                      <th>11/25 PM</th>
                      <th>11/26 AM</th>
                    </tr>
                  </thead>
                  <tbody>
                  { Object.entries(displayedData).map(([key, value], index) => (
                      <React.Fragment key={index}>
                        <tr className={value[0].Allergies.trim() === '' || value[0].Allergies.trim().toLowerCase() === 'no' || value[0].Allergies.trim().toLowerCase() === 'n/a' || value[0].Allergies.trim().toLowerCase() === 'na' || value[0].Allergies.trim().toLowerCase() === 'none' || value[0].Allergies.trim().toLowerCase().includes('ningun') ? '' : 'table-danger'}>
                          {/* Display search results */}
                          <td onClick={() => toggleRowExpansion(index)}>
                            {value[0].FirstName} {value[0].LastName}
                          </td>
                          <td onClick={() => toggleRowExpansion(index)}>{value[0].DOB} ({calculateAge(value[0].DOB)})</td>
                          {/* <td onClick={() => toggleRowExpansion(index)}>{parentObjs[value[0].parentsUUIDs[0]][0].PhoneNumber}</td> */}
                          <td onClick={() => toggleRowExpansion(index)}>{parentObjs[value[0].parentsUUIDs[0]][0].ChurchName}</td>
                          <td onClick={() => toggleRowExpansion(index)}>{value[0].Allergies}</td>
                          <td>
                            {/* Add a checkbox with an onChange event */}
                            <input
                              type="checkbox"
                              checked={firstServiceCheckedRows[key] || false}
                              onChange={(e) => handleFirstServiceCheckboxToggle(index, key, parentObjs[value[0].parentsUUIDs[0]][0].registrationDatetime, value[0].FirstName, value[0].LastName, value[0].DOB, value[0].Allergies, value[0].parentsUUIDs, e.target.checked)}
                            />
                          </td>
                          <td>
                            {/* Add a checkbox with an onChange event */}
                            <input
                              type="checkbox"
                              checked={secondServiceCheckedRows[key] || false}
                              onChange={(e) => handleSecondServiceCheckboxToggle(index, key, parentObjs[value[0].parentsUUIDs[0]][0].registrationDatetime, value[0].FirstName, value[0].LastName, value[0].DOB, value[0].Allergies, value[0].parentsUUIDs, e.target.checked)}
                            />
                          </td>
                          <td>
                            {/* Add a checkbox with an onChange event */}
                            <input
                              type="checkbox"
                              checked={thirdServiceCheckedRows[key] || false}
                              // checked={value[0].thirdCheckin}
                              onChange={(e) => handleThirdServiceCheckboxToggle(index, key, parentObjs[value[0].parentsUUIDs[0]][0].registrationDatetime, value[0].FirstName, value[0].LastName, value[0].DOB, value[0].Allergies, value[0].parentsUUIDs, e.target.checked)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="8">
                            {expandedRows.includes(index) && (
                              <table className="table table-striped table-sm table-dark">
                                <thead>
                                  <tr>
                                    <th>Nombre del Padre</th>
                                    <th>Número Telefónico</th>
                                    <th>Iglesia</th>
                                    <th>Comentario</th>
                                    <th>Hora de Registración</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {value[0].parentsUUIDs.map((parent, index) => (
                                    (parentObjs[parent] && 
                                      <tr key={index}>
                                        <td>{parentObjs[parent][0].FirstName} {parentObjs[parent][0].LastName}</td>
                                        <td><a href={"tel:" + parentObjs[parent][0].PhoneNumber} style={{color: "#FFF"}}>{"("+parentObjs[parent][0].PhoneNumber.substring(2).slice(0,3)+") "+parentObjs[parent][0].PhoneNumber.substring(2).slice(3,6)+"-"+parentObjs[parent][0].PhoneNumber.substring(2).slice(6,15)}</a></td>
                                        <td>{parentObjs[parent][0].ChurchName}</td>
                                        <td>{parentObjs[parent][0].Comments}</td>
                                        <td>{new Date(parentObjs[parent][0].registrationDatetime).toLocaleString()}</td>
                                      </tr>
                                    )
                                  ))}
                                </tbody>
                              </table>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {/* <Button variant="danger" className="d-flex mb-2"onClick={signOut}>Cerrar Sesión</Button> */}
        </div>
        )}
    </Authenticator>
      ) :
      (
        (
          <>
            <h1>LOADING...</h1>
          </>
        )
      )}
    </div>
  );
};

// export default withAuthenticator(DisplayChildData);
export default DisplayChildData;
