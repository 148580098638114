import React, { useState, useContext, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { AccountContext } from './Account';
import './Header.css'; // Import the custom CSS file
import { Button } from 'react-bootstrap';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { getSession, logout } = useContext(AccountContext);
  const navigate = useNavigate();
  useEffect(() => {
    getSession().then((session) => {
            console.log("Session: ", session);
            setIsLoggedIn(true);
        });
  }, []);

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand style={{ paddingLeft: '20px' }} as={Link} to="/">
      Retiro Regional Anaheim 2023
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-nav" className="custom-toggle" /> {/* Add the custom-toggle class */}
      <Navbar.Collapse id="navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/">Inicio</Nav.Link>
          <Nav.Link as={Link} to="/maestros">Maestros</Nav.Link> 
          {/* {!isLoggedIn 
            ? 
            <Nav.Link as={Link} to="/login">Iniciar Sesión</Nav.Link> 
            :
            <Nav.Link onClick={handleLogout}>Cerrar Sesión</Nav.Link>
          } */}
          {/* {isLoggedIn && <Nav.Link onClick={logout}>Salir</Nav.Link>} */}
        </Nav>
        {/* <div>
          {isLoggedIn && <Button onClick={logout}>Salir</Button>}
        </div> */}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
